import request from '@/utils/request'
import store from '@/store'
import _g from '../../src/global'

const MonitoringSKPService = {
  getData,
  getDataSKP,
  exportData,
  hapusData,
  editData,
  validasiData,
  getDataAktivitasPegawai,
  getDataRiwayatJabatan,
  updateSKPLama
}

function getData({
  page,
  perpage,
  search,
  order,
  order_direction,
  params,
  role,
  satuan_kerja_id
}) {
  var current = store.state.user.data
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/get-data-monitoring-skp',
    method: 'post',
    data: {
      nip: current.username,
      page: page,
      perpage: perpage ? perpage : 20,
      search: search ? search : '',
      order: order,
      order_direction: order_direction ? order_direction : 'asc',
      params: params,
      role: role,
      satuan_kerja_id: satuan_kerja_id
    }
  })
}

function getDataSKP(data) {
  return request({
    url: 'get-data-skp-monitoring-skp',
    method: 'post',
    data: {
      nip: data.nip,
      periode: data.periode
    }
  })
}

function getDataRiwayatJabatan({nip}) {
  return request({
    url: 'get-data-riwayat-jabatan-monitoring-skp',
    method: 'post',
    data: {
      nip: nip
    }
  })
}
  
function exportData(id_konf, periode) {
  var current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: 'admin/monitoring/aktivitas/export',
    method: 'post',
    data: {
      nip: current.username,
      "id_skp_konfigurasi": id_konf,
      "periode": periode
    }
  })
}

function hapusData(id) {
  var current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: 'admin/monitoring/aktivitas/pegawai/hapus',
    method: 'post',
    data: {
      nip: current.username,
      id: id,
    }
  })
}

function editData(id, super_) {
  var current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: 'admin/monitoring/aktivitas/pegawai/edit',
    method: 'post',
    data: {
      nip: current.username,
      id: id,
      super: super_
    }
  })
}

function validasiData(id, valid, nip) {
  // var current = store.state.user.current
  // let user = store.state.user
  // if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
  //   current.username = _g.overrideWithPLT(user.current.role)
  // }else{
  //   current.username = user.current.username
  // }
  return request({
    url: 'admin/monitoring/aktivitas/pegawai/validasi',
    method: 'post',
    data: {
      // nip: current.username,
      nip: nip,
      id: id,
      valid: valid
    }
  })
}

function getDataAktivitasPegawai({
  nip,
  bulan,
  awal,
  akhir,
  filter
}) {
  // var current = store.state.user.data
  // let user = store.state.user
  // if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
  //   current.username = _g.overrideWithPLT(user.current.role)
  // }else{
  //   current.username = user.current.username
  // }
  return request({
    url: 'admin/monitoring/aktivitas/get-data-aktivitas-pegawai',
    method: 'post',
    data: {
      nip: nip,
      bulan: bulan,
      awal: awal,
      akhir: akhir,
      filter: filter
    }
  })
}

function updateSKPLama({
  nip,
  periode
}) {
  return request({
    url: 'admin/monitoring/skp/kojab/update',
    method: 'post',
    data: {
      nip: nip,
      periode: periode
    }
  })
}

export default MonitoringSKPService