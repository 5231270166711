<template lang="html">
  <v-container fluid>
    <v-card>
      <v-card-title>
        <h3 class="display-2">{{ skp.riwayat ? 'Lihat Riwayat Jabatan' : 'Lihat SKP '+skp.title }}</h3>
      </v-card-title>
      <hr>
      <v-card-text>
        <v-row>
          <v-col cols="12">
          <div v-if="skp.riwayat">
            <v-data-table
              :headers="headersRiwayat"
              :items="skp"
              :loading="loading"
              :server-items-length="skp.totalKeseluruhan"
              :page.sync="page"
              :items-per-page.sync="rowPerPage"
              :footer-props="{'items-per-page-options':[10,15,20]}"
            >
            <template v-slot:item.no="{item}">
              {{item[0]}}
            </template>
            <template v-slot:item.jabatan="{item}">
              <br><div>{{item[1]}}</div><br>
            </template>
            <template v-slot:item.eselon="{item}">
              <div>{{ item[2] }}</div>
            </template>
            <template v-slot:item.golongan="{item}">
              <div>{{item[3]}}</div>
            </template>
            <template v-slot:item.bulan="{item}">
              <div>{{item[4]}}</div>
            </template>
            </v-data-table>
          </div>
          <div v-else>
            <v-data-table
              :headers="headers"
              :items="skp"
              :loading="loading"
              :server-items-length="skp.totalKeseluruhan"
              :page.sync="page"
              :items-per-page.sync="rowPerPage"
              :footer-props="{'items-per-page-options':[10,15,20]}"
            >
            <template v-slot:item.no="{item, index}">
              {{index+1}}
            </template>
            <template v-slot:item.skp="{item}">
              <br><div>{{item[1]}}</div><br>
            </template>
            <template v-slot:item.target="{item}">
              <div>{{ item[2] }}</div>
            </template>
            <template v-slot:item.satuan_target="{item}">
              <div>{{item[3]}}</div>
            </template>
            <template v-slot:item.realisasi="{item}">
              <div>{{item[4]}}</div>
            </template>
            <template v-slot:item.satuan_realisasi="{item}">
              <div>{{item[5]}}</div>
            </template>
            <template v-slot:item.keterangan="{item}">
              <div>{{item[6]}}</div>
            </template>
            <template v-slot:item.status_skp="{item}">
              <div><b>{{item[7]}}</b></div>
            </template>
            </v-data-table>
          </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="updateKojab(pegawai)" color="green white--text darken-1">Pindahkan SKP {{skp.title}} Jabatan Lama Ke Jabatan Saat Ini</v-btn>
        <v-btn @click="closeEvent()" color="yellow darken-1">tutup</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import MonitoringSKPService from '@/services/MonitoringSKPService'
import store from '@/store'
import _ from 'lodash'
export default {
  props:{
    skp:Array,
    pegawai:Object
  },
  data(){
    return{
      loading:false,
      headers:[
        {text:'No', value:'no', sortable:false, class:'header-index-profile'},
        {text:'Sasaran Kinerja Tahunan', value:'skp', sortable:false, class:'header-index-profile'},
        {text:'Target', value:'target', sortable:false, class:'header-index-profile'},
        {text:'Satuan Target', value:'satuan_target', sortable:false, class:'header-index-profile'},
        {text:'Realisasi', value:'realisasi', sortable:false, class:'header-index-profile'},
        {text:'Satuan Realisasi', value:'satuan_realisasi', sortable:false, class:'header-index-profile'},
        {text:'Keterangan', value:'keterangan', sortable:false, class:'header-index-profile'},
        {text:'Status SKP', value:'status_skp', sortable:false, class:'header-index-profile'},
        {value:'aksi', sortable:false, class:'header-index-profile'},
      ],
      headersRiwayat:[
        {text:'No', value:'no', sortable:false, class:'header-index-profile'},
        {text:'Jabatan', value:'jabatan', sortable:false, class:'header-index-profile'},
        {text:'Eselon', value:'eselon', sortable:false, class:'header-index-profile'},
        {text:'Golongan', value:'golongan', sortable:false, class:'header-index-profile'},
        {text:'Bulan', value:'bulan', sortable:false, class:'header-index-profile'},
      ],
      rowPerPage:10,
      page:1
    }
  },

  methods:{
    closeEvent(){
      this.$emit('onClose',false)
    },
    updateKojab(p){
      MonitoringSKPService.updateSKPLama({
        nip:p.peg_nip,
        periode:p.periode })
      .then((response)=>{
        let res = response.data
        if(res.success){
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Get Data Berhasil'), color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      }).finally(()=>{
        this.loading = false
      })
    }

  },
}
</script>

<style lang="css" scoped>
</style>
